<style scoped>
    
</style>
<template>
    <div class="w-100 h-100 d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center w-100">
            <div class="border border-info rounded-1rem p-3 bg-secondary bg-opacity-50 d-flex flex-column">
                <div class="display-4 text-info mt-3 mb-5 order-1">
                    <i class="bi bi-person-circle d-flex justify-content-center"></i>
                </div>
                <div :class="{'invisible':isClient}" class="border border-info rounded-pill mb-3 d-flex px-3 text-white order-2">
                    <i class="bi bi-person d-flex flex-column justify-content-center fs-5"></i>
                    <input ref="txtAccount" v-model.trim="txtAccount" class="bg-transparent border-0 form-control shadow-none text-white" type="text" placeholder="账号">
                </div>
                <div :class="{'invisible':isClient}" class="border border-info rounded-pill mb-3 d-flex px-3 text-white order-3">
                    <i class="bi bi-lock d-flex flex-column justify-content-center fs-5"></i>
                    <input ref="txtPwd" v-model.trim="txtPwd" class="bg-transparent border-0 form-control shadow-none text-white" type="password" placeholder="密码">
                </div>
                <div :class="[isClient?'order-2':'order-4']" class="d-grid">
                    <button class="btn btn-primary btn-block rounded-pill" type="button" @click="login">{{ isClient?'客户一键登录':'客服登录' }}</button>
                </div>
                <div class="text-center order-5">
                    <!-- <span class="btn btn-link text-info" @click="isClient=!isClient">{{isClient?'我是客服':'我是客户'}}</span> -->
                </div>
            </div>
        </div>
        <div v-if="isShowLoad" class="position-absolute w-100 h-100 bg-dark bg-opacity-50 top-0 d-flex justify-content-center align-items-center">
            <load text="登录中"></load>
        </div>
    </div>
</template>
<script>
import Load from "@/components/Load.vue";
import { IS_DEBUG,INTERFACE,USER_TYPE,LOGIN_RESULT,USER_VIP } from "@/assets/js/const.js"
import { guid } from "@/assets/js/common.js"
import { useLayer } from "@/assets/js/useLayer.js"
export default {
    props: ["type","uid","token"],
    components: { Load },
    emits: ['connWebsocket','sendWebsocket','msgWebsocket'],
    data() {
        return {
            isClient:true,//是否客户登录模式
            isShowLoad:false,//是否显示加载
            isLoginEnable:true,//登录按钮是否激活
            txtAccount:"",//账号
            txtPwd:"",//密码
        };
    },
    created() {
        this.isClient=this.type=='server'?false:true;
    },
    mounted() {
        IS_DEBUG&&(this.txtAccount="kf01")&&(this.txtPwd="123456");
        window.addEventListener("keyup",this.keyupEnter);
        this.uid&&this.login();
    },
    unmounted() {
        window.removeEventListener("keyup",this.keyupEnter);
    },
    methods:{
        /**
         * 回车事件
         */
        keyupEnter(e){
            (e.keyCode=="13")&&this.login();
        },
        /**
         * 登录
         */
        login(){
            if(this.isLoginEnable){
                this.isLoginEnable=false;
                if(!this.isClient){
                    if(this.txtAccount==""){
                        useLayer({str:"用户名不能为空"});
                        this.$refs.txtAccount.focus();
                        this.isLoginEnable=true;
                    }else if(this.txtPwd==""){
                        useLayer({str:"密码不能为空"});
                        this.$refs.txtPwd.focus();
                        this.isLoginEnable=true;
                    }else{
                        this.setWebsocket(this.txtAccount,this.txtPwd,"",USER_TYPE.server);
                    }
                }else{
                    let account=guid();//游客随机账号
                    (this.uid)&&(account=this.uid);
                    let session=sessionStorage.getItem("id");
                    if(session){
                        account=session;
                    }else{
                        sessionStorage.setItem("id",account);
                    }
                    this.setWebsocket(account,"",this.uid?account:"游客",USER_TYPE.client);
                }
            }
        },
        /**
         * 设置websocket
         * @param {String} id 账号
         * @param {String} pwd 密码
         * @param {String} name 昵称
         * @param {USER_TYPE} type 用户类型
         */
        setWebsocket(id,pwd,name,type){
            let g=guid();
            this.isShowLoad=true;
            this.$emit('connWebsocket',()=>{
                this.$emit('sendWebsocket',{
                    fun:INTERFACE.login,
                    id:id,
                    pwd:pwd,
                    name:name,
                    type:type,
                    guid:g,
                    isvip:USER_VIP.false
                })
            })
            this.$emit('msgWebsocket',(data)=>{
                switch (data["fun"]) {
                    case INTERFACE.login:
                        this.isShowLoad=false;
                        let loginResult=data["result"];
                        if(loginResult==LOGIN_RESULT.success){
                            this.$router.push({
                                name:'home',
                                state:{
                                    id,
                                    name:data["name"],
                                    type,
                                    guid:g,
                                    power:data["power"],
                                    imgurl:data["iurl"]
                                }
                            },);
                        }else{
                            useLayer({str:"登录失败"});
                        }
                        this.isLoginEnable=true;
                        break;
                }
            })
        },
    },
    watch: {
        /**
         * 监听当前登录模式改变
         */
        isClient(newVal) {
            if(!newVal){
                this.$nextTick(()=>{
                    this.$refs.txtAccount.focus();
                })
            }
        },
    },
}
</script>
