<style>
	.bg{
		background: url('@/assets/images/bg.jpg') no-repeat center;
		background-size: cover;
	}
	.disabled,[disabled]{
		pointer-events:unset !important;
		cursor: not-allowed !important;
	}
	.filter{
		filter: blur(4px);
	}
	.h-0{
		height:0;
	}
	.index-down{
		z-index: -1;
	}
	.overflow-y-scroll{
		overflow-y: scroll;
	}
	.rounded-1rem{
	  border-radius: 1rem;
	}
	.scroll-none::-webkit-scrollbar{
		display: none;
	}
	.textarea-noresize{
		resize:none;
	}
	.w-0{
		width: 0;
	}
	@media (max-width: 575px){
		.rounded-xs-0{
			border-radius: 0!important;
		}
	}
</style>
<template>
	<div class="bg filter w-100 h-100 position-absolute index-down"></div>
	<router-view @conn-websocket="connWebsocket" @msg-websocket="msgWebsocket" @send-websocket="sendWebsocket" @close-websocket="closeWebsocket"/>
</template>
<script>
import { IS_DEBUG,INTERFACE,LAYER_TYPE } from "@/assets/js/const.js"
import { nextTick } from '@vue/runtime-core';
import { useLayer } from "@/assets/js/useLayer.js"
export default {
	data() {
		return {
			ws: null,
		};
	},
	methods: {
		/**
		 * 连接websocket
		 * @param {Function} onopen 连接成功事件
		 */
		connWebsocket(onopen) {
			this.ws=new WebSocket(config().ws);
			onopen&&(this.ws.onopen=onopen);
			this.websocketClose();
		},
		/**
		 * websocket响应事件
		 * @param {Function} onmessage 
		 */
		msgWebsocket(onmessage){
			if(this.ws){
				let onlineTimer,timer;//心跳定时器、线路优劣验证定时器
				let invalid="";//无效字符串
				this.ws.onmessage=async (e)=>{
					IS_DEBUG&&console.log("接收："+e.data);
					
					//数据包处理
					let obj=this.setSocketData(e.data,invalid);
					let arr=obj.arr;
					invalid=obj.invalid;
	
					//接口响应
					for (let i = 0; i < arr.length; i++){
						let data = arr[i];
						IS_DEBUG&&console.log("接收【转换】："+JSON.stringify(data));
						onmessage&&onmessage(data);
						await nextTick();//加上这个以免因连包导致循环赋值，但因为vue的机制使循环最后一次才能监听到
					}
	
				}
			}
		},
		/**
		 * websocket发送事件
		 * @param {Object} obj 
		 */
		sendWebsocket(obj){
			if(this.ws){
				IS_DEBUG&&console.log("发送："+JSON.stringify(obj));
				this.ws.send(JSON.stringify(obj));
			}
		},
		/**
		 * 关闭websocket
		 */
		closeWebsocket(){
			if(this.ws){
				console.log("主动关闭websocket");
				this.ws.close();
				this.ws=null;
			}
		},
		/**
		 * websocket关闭事件
		 */
		websocketClose(){
			if(this.ws){
				this.ws.onclose=(e)=>{
					console.log(e.code);
					if(e.code==1006){
						console.log("被动关闭websocket");
						useLayer({
							type:LAYER_TYPE.confirm,
							str:"您已处于离线状态，请重新登录",
							btn:["重新登录"],
							yesFn:()=>{
								this.$router.push({name:'login'});
							}
						})
						this.ws=null;
					}
				}
			}
		},
		/**
         * 处理websocket接收的数据
         */
		setSocketData(str,invalid){
            //数据包处理
            let arr=[];//存放接收到的数据
            try {
                let obj1 = JSON.parse(str);//尝试转换接收到的数据
                arr.push(obj1);//成功则加入数组作为一条有效数据
            } catch (error) {
                while (str.indexOf("}{")!=-1) {//连包
                    IS_DEBUG&&console.log("有连包");
                    let part=str.substring(0,str.indexOf("}{")+1);//截取背向花括号前的片段
                    str = str.replace(part,"");
                    try {
                        let obj2 =JSON.parse(part);//尝试转换截取出来的片段
                        arr.push(obj2);//成功则加入数组作为一条有效数据
                    } catch (error) {//失败说明是不完整数据
                        invalid+=part;//与上一次的无效数据拼一起
                        try {
                            let obj3=JSON.parse(invalid);//尝试转换拼接后的字符串
                            arr.push(obj3);//成功则加入数组作为一条有效数据
                            invalid="";//并且清空无效字符串
                        } catch (error) {}//不成功不理会
                    }
                }
                //走到这里说明没有连包了
                try {
                    let obj4=JSON.parse(str);//尝试转换剩余的片段
                    arr.push(obj4);//成功则加入数组作为一条有效数据
                } catch (error) {//失败则说明是不完整数据
                    IS_DEBUG&&console.log("有分包");
                    invalid+=str;//与上一次的无效数据拼一起
                    try {
                        let obj5=JSON.parse(invalid);//尝试转换拼接后的字符串
                        arr.push(obj5);//成功则加入数组作为一条有效数据
                        invalid="";//并且清空无效字符串
                    } catch (error) {}//不成功则不理会，等待下一次接收数据再处理就好
                }
            }
            return {
				arr,invalid
			};
        },
	},
	watch: {
		$route(to) {//监听路由变化
			if((!this.ws)&&(to.name!="media"&&to.name!="login"&&to.name!="app")){
				this.$router.push({name:'login'});
			}
		}
	},
}
</script>
